<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="75">
    <v-btn class="mr-3" elevation="1" fab small @click="setDrawer(!drawer)">
      <v-icon v-if="value"> mdi-view-quilt </v-icon>

      <v-icon v-else> mdi-dots-vertical </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.meta.layout"
    />

    <v-spacer />
    <!--
    <v-text-field
      :label="$t('search')"
      color="secondary"
      hide-details
      style="max-width: 165px"
    >
      <template v-if="$vuetify.breakpoint.mdAndUp" v-slot:append-outer>
        <v-btn class="mt-n2" elevation="1" fab small>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-text-field>
-->
    <div class="mx-3" />
    <!--
    <v-btn class="ml-2" min-width="0" text to="/">
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn>
-->
    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          v-show="admin"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-badge color="red" overlap>
            <template v-slot:badge>
              <span>{{ NbrNotifications }}</span>
            </template>

            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list :title="false" nav>
        <div>
          <app-bar-item v-show="NbrContacts && NbrContacts != 0">
            <v-btn @click="redirectToContacts" large raised text x-small
              >Nouveaux contacts</v-btn
            >
            <v-badge color="red" overlap>
              <template v-slot:badge>
                <span>{{ NbrContacts }}</span>
              </template>

              <v-icon>mdi-bell</v-icon>
            </v-badge>
          </app-bar-item>
          <app-bar-item v-show="NbrRecruitments && NbrRecruitments != 0">
            <v-btn @click="redirectToEditRecruitments" large raised text x-small
              >Nouvelles candidatures</v-btn
            >
            <v-badge color="red" overlap>
              <template v-slot:badge>
                <span>{{ NbrRecruitments }}</span>
              </template>

              <v-icon>mdi-bell</v-icon>
            </v-badge>
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>
    <!--
    <v-btn class="ml-2" min-width="0" text to="/pages/user">
      <v-icon>mdi-account</v-icon>
    </v-btn>
    -->
  </v-app-bar>
</template>

<script>
// Components
import { VHover, VListItem } from "vuetify/lib";

// Utilities
import { mapState, mapMutations } from "vuex";

// Import Firebase
import firebase from "firebase";

export default {
  name: "DashboardCoreAppBar",

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text primary elevation-12": hover,
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default
              );
            },
          },
        });
      },
    },
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      NbrContacts: null,
      NbrRecruitments: null,
      NbrNotifications: null,
      admin: false,
    };
  },

  computed: {
    ...mapState(["drawer"]),
  },

  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),
    async NotificationNumber() {
      this.NbrContacts = localStorage.getItem("NbrContacts");
      this.NbrRecruitments = localStorage.getItem("NbrRecruitments");
    },

    redirectToContacts() {
      this.$router.push({
        name: "Contacts",
      });
    },
    redirectToEditRecruitments() {
      this.$router.push({
        name: "Recruitments",
      });
    },
  },
  beforeMount() {
    if (firebase.auth().currentUser) {
      firebase
        .auth()
        .currentUser.getIdTokenResult()
        .then((tokenResult) => {
          if (tokenResult.claims.admin) {
            this.admin = true;
            this.NotificationNumber();
            this.NbrNotifications =
              parseInt(this.NbrContacts) + parseInt(this.NbrRecruitments);
          }
        });
    }
  },
};
</script>
